
/**
* @fileOverview oi - A tiny form validation library for custom error messages
* @version 0.3.0
*/

var oi = (function (document, undefined) {

    'use strict';

    var opts = {};

    var oiId = 'data-oi-id';

    var ariaInvalid = 'aria-invalid';

    var msgPrefix = 'data-msg';

    var allFieldsSelector = 'input, select, textarea';

    /**
    * Init oi
    * @memberOf oi
    * @param {args} options for oi
    */
    function init(args) {

        args = args || {};

        opts = {
            formSelector: args.formSelector || document.getElementsByTagName('form'),
            errorHTML: args.errorHTML || '<span class="oi-message" ' + oiId + '="{{id}}" role="alert">{{message}}</span>',
            errorPosition: args.errorPosition || 'afterend',
            errorScrollOffset: args.errorScrollOffset || 75,
            interactedClass: args.interactedClass || 'oi-has-interacted',
            onInvalid: args.onInvalid,
            onValid: args.onValid,
            watchInputs: ((args.watchInputs === undefined) ? true : args.watchInputs),
            validateHidden: ((args.validateHidden === undefined) ? false : args.validateHidden)
        };

        if ('required' in document.createElement('input')) {

            // setup forms
            each(opts.formSelector, function (item) {
                setupForm(item);
                if (opts.watchInputs) {
                    setupInputWatches(item);
                }
            });

        }

    }

    /**
    * Sets up a form for validation
    * @memberOf oi
    * @param {form} form to set up
    */
    function setupForm(form) {

        form.noValidate = true;
        form.addEventListener('submit', function (e) {
            var self = this;
            if (!self.checkValidity()) {
                e.preventDefault();
                getMessages(self);
            }
        }, true);

    }

    /**
    * Validates an individual input
    * @memberOf oi
    * @param {currentInput} input to validate
    */
    function validateInput(currentInput) {

        if (opts.validateHidden) {
            if (isHidden(currentInput)) { // don't validate hidden inputs
                return;
            }
        }

        matchValidate(currentInput); // if values need to be compared
        if (currentInput.getAttribute('type') === 'url') { // check urls because of protocol
            checkUrl(currentInput);
        }
        if (!currentInput.checkValidity()) {
            setMessage(currentInput);
        } else {
            currentInput.setAttribute(ariaInvalid, 'false');
            var errorMessage = document.querySelector('[' + oiId + '="' + currentInput.id + '"]');
            if (errorMessage) {
                errorMessage.textContent = '';
                errorMessage.setAttribute('aria-hidden', 'true');
            }
            if (opts.onValid) {
                opts.onValid(currentInput);
            }
        }

        currentInput.classList.add(opts.interactedClass);

    }

    /**
    * Compares two fields
    * @memberOf oi
    * @param {currentInput} input to check against
    */
    function matchValidate(currentInput) {

        var sourceInput;
        var copyInput;
        var shouldMatch = false;

        if (currentInput.hasAttribute('data-has-match')) { // source of match
            sourceInput = currentInput;
            copyInput = document.getElementById(currentInput.getAttribute('data-has-match'));
            shouldMatch = true;
        }

        if (currentInput.hasAttribute('data-match')) { // copy of match
            sourceInput = document.getElementById(currentInput.getAttribute('data-match'));
            copyInput = currentInput;
            shouldMatch = true;
        }

        if (shouldMatch && sourceInput && copyInput) {
            if (sourceInput.value !== copyInput.value) {
                copyInput.setCustomValidity(copyInput.getAttribute(msgPrefix + '-match'));
                setMessage(copyInput);
            } else {
                copyInput.setCustomValidity('');
            }
        }
    }

    /**
    * Sets up input watches
    * @memberOf oi
    * @param {context} context of input watches
    */
    function setupInputWatches(context) {

        var fields = (context || document).querySelectorAll(allFieldsSelector);
        each(fields, function (item) {
            item.addEventListener('change', function (e) {
                var currentField = e.target;
                validateInput(currentField);
            }, true);

        });

    }

    /**
    * Get all messages for the current context
    * @memberOf oi
    * @param {context} the context to get messages for
    */
    function getMessages(context) {

        // validate all inputs
        each(context.querySelectorAll(allFieldsSelector), function (item) {
            validateInput(item);
        });

        var invalidInputs = context.querySelectorAll('input:invalid, select:invalid, textarea:invalid');

        if (invalidInputs.length > 0) {
            if (!isInputTextareaOrSelect(document.activeElement)) {
                setFocusAndScroll(invalidInputs[0]);
            } else {
                if (document.activeElement.getAttribute("aria-invalid") === "false") {
                    setFocusAndScroll(invalidInputs[0]);
                }
            }
        }
    }

    /**
    * Set the message for a given input
    * @memberOf oi
    * @param {input} input to set message for
    */
    function setMessage(input) {

        input.setAttribute(ariaInvalid, 'true');
        var inputValidity = input.validity;
        var message = ((inputValidity.valueMissing) ? input.getAttribute(msgPrefix + '-required') : false) ||
            ((inputValidity.typeMismatch) ? input.getAttribute(msgPrefix + '-type') : false) ||
            ((inputValidity.patternMismatch) ? input.getAttribute(msgPrefix + '-pattern') : false) ||
            ((inputValidity.patternMismatch) ? input.getAttribute(msgPrefix + '-regex') : false) || 
            ((inputValidity.tooShort) ? input.getAttribute(msgPrefix + '-short') : false) ||
            ((inputValidity.tooLong) ? input.getAttribute(msgPrefix + '-long') : false) ||
            ((inputValidity.customError) ? input.getAttribute(msgPrefix + '-custom') : false) ||
            input.getAttribute(msgPrefix) ||
            input.validationMessage; // fallback to the browser default message

        var errorMessage = document.querySelector('[' + oiId + '="' + input.id + '"]');
        if (!errorMessage) {
            input.insertAdjacentHTML(opts.errorPosition, template(opts.errorHTML, { id: input.id, message: message }));
        } else {
            errorMessage.textContent = message;
            errorMessage.setAttribute('aria-hidden', 'false'); 
        }

        if (opts.onInvalid) {
            opts.onInvalid(input);
        }

    }

    /**
    * Sets the focus on an input and scrolls to the position
    * @memberOf oi
    * @param {input} input to check
    */
    function setFocusAndScroll(el) {

        el.focus(); // focus on the first element if the current active element is valid otherwise stay where the user is
        scrollToElement(el); // scroll to the position of that element

    }

    /**
    * Checks that url contains a protocol because Chrome etc expects one
    * @memberOf oi
    * @param {input} input to check
    */
    function checkUrl(input) {

        var inputValue = input.value;
        if (inputValue && inputValue.search(/^http[s]?\:\/\//) === -1) {
            inputValue = "http://" + inputValue;
        }
        input.value = inputValue;

    }

    /**
    * Checks if an element is hidden
    * @memberOf oi
    * @param {el} input to check
    */
    function isHidden(el) {
        return (el.offsetParent === null);
    }

    /**
    * Scroll to element position
    * @memberOf oi
    * @param {el} input to scroll to
    */
    function scrollToElement(el) {
        var scrollTargetPosition = el.getBoundingClientRect().top - opts.errorScrollOffset + (window.pageYOffset || document.documentElement.scrollTop);
        window.scrollTo(0, scrollTargetPosition);
    }

    /**
    * Checks if an element is a tag type of input, textarean or select
    * @memberOf oi
    * @param {el} input to check
    */
    function isInputTextareaOrSelect(el) {

        var tagName = el.tagName;
        if (tagName) {
            return tagName.toLowerCase() === 'input' || tagName.toLowerCase() === 'textarea' || tagName.toLowerCase() === 'select';
        } else {
            return false;
        }

    }

    /**
    * Simple each utility
    * @memberOf oi
    * @param {value} value to loop through
    * @param {cb} callback on each item
    */
    function each(value, cb) {

        for (var i = 0, len = value.length; i < len; i++) {
            cb(value[i]);
        }

    }

    /**
    * Simple template
    * @memberOf oi
    * @param {templateString} the string to template
    * @param {data} data to replace
    */
    function template(templateString, data) {

        for (var property in data) {
            templateString = templateString.replace(new RegExp('{{' + property + '}}', 'g'), data[property]);
        }
        return templateString;

    }

    // public api
    return {
        init: init,
        validateInput: validateInput,
        validateForm: getMessages
    };

})(document);


//site.html = document.getElementsByTagName('html')[0];

//site.body = document.getElementsByTagName('body')[0];

var Helpers = (function () {

    function Helpers() {

    }

    Helpers.debounce = function (fn, delay) {

        var timer = null;

        delay = delay || 300;

        return function () {

            var context = this;

            var args = arguments;

            clearTimeout(timer);

            timer = setTimeout(function () {

                fn.apply(context, args);

            }, delay);

        };

    };

    Helpers.scopedLoop = function (list, fn, context) {

        for (var i = 0, len = list.length; i < len; i++) {

            (function (i) {

                fn(list[i], i, context);

            }).call(this, i);

        }

    };

    Helpers.createCustomEventForDispatch = function (eventName, data) {

        if (data === void 0) { data = {}; }

        var event;

        if (window.CustomEvent && typeof window.CustomEvent === 'function') {

            event = new CustomEvent(eventName, { detail: data });

        }

        else {

            event = document.createEvent('CustomEvent');

            event.initCustomEvent(eventName, true, true, data);

        }

        return event;

    };

    Helpers.isLegacyIE = function () {

        var ua = window.navigator.userAgent;

        var msie = ua.indexOf('MSIE ');

        var trident = ua.indexOf('Trident/');

        var edge = ua.indexOf('Edge');

        return (msie > 0 || trident > 0 || edge > 0);

    };

    Helpers.guid = function () {

        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {

            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);

            return v.toString(16);

        });

    };

    Helpers.serialize = function (form, makeArray) {

        if (makeArray === void 0) { makeArray = false; }

        var serialized = [];

        var elements = form.querySelectorAll('input,select,textarea');

        for (var i = 0; i < elements.length; i++) {

            var field = elements[i];

            if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') {

                continue;

            }

            if (field.type === 'select-multiple') {

                for (var j = 0; j < field.options.length; j++) {

                    if (!field.options[j].selected)

                        continue;

                    if (makeArray) {

                        serialized.push({

                            name: encodeURIComponent(field.name),

                            value: encodeURIComponent(field.options[j].value)

                        });

                    }

                    else {

                        serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.options[j].value));

                    }

                }

            }

            else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {

                if (makeArray) {

                    serialized.push({

                        name: encodeURIComponent(field.name),

                        value: encodeURIComponent(field.value)

                    });

                }

                else {

                    serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value));

                }

            }

        }

        if (makeArray) {

            return serialized;

        }

        return serialized.join('&');

    };

    Helpers.getParameter = function (name, locationSearch) {

        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");

        if (!locationSearch) {

            locationSearch = location.search;

        }

        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");

        var results = regex.exec(locationSearch);

        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));

    };

    Helpers.getCulture = function () {

        return document.documentElement.getAttribute('lang');

    };

    Helpers.getUTCDateFromString = function (input) {

        var componentsArray = input.split(',');

        var dateComponents = {

            year: parseInt(componentsArray[0]),

            month: parseInt(componentsArray[1]) - 1,

            day: parseInt(componentsArray[2]),

            hours: parseInt(componentsArray[3]),

            minutes: parseInt(componentsArray[4]),

            seconds: parseInt(componentsArray[5]),

            milliseconds: 0

        };

        return new Date(Date.UTC(dateComponents.year, dateComponents.month, dateComponents.day, dateComponents.hours, dateComponents.minutes, dateComponents.seconds, dateComponents.milliseconds));

    };

    Helpers.getPercentage = function (numberOne, numberTwo, isDecimal) {

        var percentOne = numberOne;

        var percentTwo = numberTwo;

        if (isDecimal) {

            percentOne = percentOne * 100;

            percentTwo = percentTwo * 100;

            var percentOneString = percentOne.toString();

            var percentTwoString = percentTwo.toString();

            percentOne = Math.round(parseFloat(percentOneString));

            percentTwo = Math.round(parseFloat(percentTwoString));

        }

        else {

            var total = percentOne + percentTwo;

            if (total > 0) {

                percentOne = (percentOne / total) * 100;

                percentTwo = (percentTwo / total) * 100;

            }

            else {

                percentOne = 50;

                percentTwo = 50;

            }

        }

        return {

            percentOne: percentOne,

            percentTwo: percentTwo

        };

    };

    Helpers.scrollToElement = function (element) {

        var offset = 0;

        var header = document.querySelector('[data-header]');

        if (header) {

            var styles = getComputedStyle(header);

            if (styles.position && styles.position.toLowerCase() === 'fixed')

                offset = -Math.abs(header.offsetHeight);

        }

        var position = element.getBoundingClientRect().top + window.pageYOffset + offset;

        window.scroll({ top: position, left: 0, behavior: 'smooth' });

    };

    return Helpers;

}());

new Helpers();

window.Helpers = Helpers;


var Header = (function () {
    function Header() {
        var _this = this;
        this.lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
        this.headerElement = document.querySelector('.js-header');
        this.navListMobileContainerElement = document.querySelector('.js-nav-list-mobile-container');
        this.scrollingDownCSSClass = 'scrolling-down';
        this.scrollingUpCSSClass = 'scrolling-up';
        if (!this.headerElement) {
            return;
        }
        this.subNavigation();
        this.mobileNavLock();
        this.scrolling();
        this.offClick();
        setTimeout(function () {
            _this.headerElement.classList.remove('c-header--loading');
        }, 300);
    }
    Header.prototype.offClick = function () {
        var _this = this;
        document.addEventListener('click', function (e) {
            var targetElement = e.target;
            if (!targetElement.closest('.js-header')) {
                _this.closeOpenSubNavs();
            }
        });
    };
    Header.prototype.subNavigation = function () {
        var _this = this;
        var subNavTriggers = document.querySelectorAll('.js-nav-sub-link-trigger');
        Helpers.scopedLoop(subNavTriggers, function (subNavTrigger, i, context) {
            var isSubSubTrigger = subNavTrigger.classList.contains('js-nav-sub-sub-link-trigger');
            subNavTrigger.addEventListener('click', function (e) {
                e.preventDefault();
                context.closeOpenSubNavs(subNavTrigger, isSubSubTrigger);
                var targetSubNav = document.querySelector('#' + subNavTrigger.getAttribute('aria-controls'));
                var shouldBeExpanded = subNavTrigger.getAttribute('aria-expanded').toLowerCase() === 'false';
                if (shouldBeExpanded && !_this.isDesktop() && !subNavTrigger.classList.contains('js-nav-mobile-trigger')) {
                    targetSubNav.style.maxHeight = targetSubNav.scrollHeight + 'px';
                    if (isSubSubTrigger && targetSubNav.closest('.c-nav__sub-nav-container')) {
                        targetSubNav.closest('.c-nav__sub-nav-container').removeAttribute('style');
                    }
                }
                else {
                    targetSubNav.removeAttribute('style');
                }
                subNavTrigger.setAttribute('aria-expanded', shouldBeExpanded + '');
                targetSubNav.setAttribute('aria-expanded', shouldBeExpanded + '');
                if (subNavTrigger.classList.contains('js-nav-mobile-trigger')) {
                    if (shouldBeExpanded) {
                        _this.setMobileOpenHeight();
                    }
                    else {
                        _this.removeMobileOpenHeight();
                    }
                }
            });
        }, this);
    };
    Header.prototype.closeOpenSubNavs = function (excludedElement, closeOnlySubSubNavs) {
        var subNavTriggers = document.querySelectorAll('.js-nav-sub-link-trigger[aria-expanded="true"]:not(.js-nav-mobile-trigger)');
        Helpers.scopedLoop(subNavTriggers, function (subNavTrigger, i, context) {
            if (excludedElement && subNavTrigger.isEqualNode(excludedElement)) {
                return;
            }
            if (closeOnlySubSubNavs && !subNavTrigger.classList.contains('js-nav-sub-sub-link-trigger')) {
                return;
            }
            var targetSubNav = document.querySelector('#' + subNavTrigger.getAttribute('aria-controls'));
            targetSubNav.removeAttribute('style');
            subNavTrigger.setAttribute('aria-expanded', 'false');
            targetSubNav.setAttribute('aria-expanded', 'false');
        }, this);
        this.navListMobileContainerElement.setAttribute('style', '');
    };
    Header.prototype.mobileNavLock = function () {
        var _this = this;
        var isMobileNavOpen = false;
        var mobileNavOpenCSSClass = 'mobile-nav-open';
        var scrollPosition = document.documentElement.scrollTop;
        document.querySelector('.js-nav-mobile-trigger').addEventListener('click', function () {
            isMobileNavOpen = !isMobileNavOpen;
            if (isMobileNavOpen) {
                scrollPosition = document.documentElement.scrollTop;
                document.documentElement.classList.add(mobileNavOpenCSSClass);
                window.addEventListener('resize', checkIfChangedToDesktopNav);
                _this.navListMobileContainerElement.scrollTop = 0;
            }
            else {
                document.documentElement.classList.remove(mobileNavOpenCSSClass);
                window.scrollTo(0, scrollPosition);
                window.removeEventListener('resize', checkIfChangedToDesktopNav);
            }
        });
        var checkIfChangedToDesktopNav = Helpers.debounce(function () {
            if (_this.isDesktop()) {
                document.documentElement.classList.remove('mobile-nav-open');
                _this.navListMobileContainerElement.setAttribute('aria-expanded', 'false');
                _this.closeOpenSubNavs();
                _this.removeMobileOpenHeight();
            }
            else {
                if (document.documentElement.classList.contains('mobile-nav-open')) {
                    _this.setMobileOpenHeight();
                }
            }
        }, 200);
    };
    Header.prototype.scrolling = function () {
        var _this = this;
        if (this.lastScrollTop > 0) {
            this.headerElement.classList.add(this.scrollingDownCSSClass);
            this.headerElement.classList.remove(this.scrollingUpCSSClass);
        }
        var debouncedScrollCheck = Helpers.debounce(function () {
            window.requestAnimationFrame(function () {
                _this.scrollCheck();
            });
        }, 50);
        window.addEventListener("touchmove", debouncedScrollCheck);
        window.addEventListener("scroll", debouncedScrollCheck);
    };
    Header.prototype.scrollCheck = function (elementToCheck) {
        var scrollTop = elementToCheck ? elementToCheck.scrollTop : (window.pageYOffset || document.documentElement.scrollTop);
        if (scrollTop === this.lastScrollTop) {
            return;
        }
        var headerHeight = this.headerElement.offsetHeight;
        if (scrollTop > this.lastScrollTop && scrollTop > headerHeight) {
            this.headerElement.classList.add(this.scrollingDownCSSClass);
            this.headerElement.classList.remove(this.scrollingUpCSSClass);
            document.documentElement.classList.add(this.scrollingDownCSSClass);
            document.documentElement.classList.remove(this.scrollingUpCSSClass);
        }
        else {
            this.headerElement.classList.add(this.scrollingUpCSSClass);
            this.headerElement.classList.remove(this.scrollingDownCSSClass);
            document.documentElement.classList.add(this.scrollingUpCSSClass);
            document.documentElement.classList.remove(this.scrollingDownCSSClass);
        }
        this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    };
    Header.prototype.setMobileOpenHeight = function () {
        var mobileNavWrapper = document.querySelector('.js-mobile-header-wrapper');
        mobileNavWrapper.style.height = window.innerHeight + "px";
    };
    Header.prototype.removeMobileOpenHeight = function () {
        var mobileNavWrapper = document.querySelector('.js-mobile-header-wrapper');
        mobileNavWrapper.removeAttribute('style');
    };
    Header.prototype.isDesktop = function () {
        return window.matchMedia('(min-width: 1100px)').matches;
    };
    return Header;
}());
new Header();

var FormValidation = (function () {
    function FormValidation() {
        oi.init({
            formSelector: document.querySelectorAll('.js-validate-form'),
            errorHTML: '<span class="c-error-message" data-oi-id="{{id}}" role="alert">{{message}}</span>',
            errorClass: 'c-error-message',
            errorPosition: 'afterend',
            errorScrollOffset: 75,
            interactedClass: 'has-interacted',
            watchInputs: true,
            validateHidden: false
        });
    }
    return FormValidation;
}());
new FormValidation();
window.Helpers = FormValidation;

var Detection = (function () {
    function Detection() {
        this.modernBrowser = 'noModule' in HTMLScriptElement.prototype;
        this.canHover();
    }
    Detection.prototype.canHover = function () {
        var canHover = window.matchMedia('(hover: hover)').matches && window.matchMedia('(min-width: 768px)').matches;
        if (!canHover && this.modernBrowser) {
            document.documentElement.classList.remove('html--can-hover');
        }
    };
    return Detection;
}());
new Detection();
window.Helpers = Detection;

